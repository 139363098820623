export default {
  siteMetadata: {
    title: 'Sikka',
    description:
      'Sikka is the best earning app to earn real cash rewards! All you need to do is complete simple offers daily to start earning money right now. Download now.',
    siteUrl: 'https://sikkaapp.in/',
    social: {
      twitter: 'sikka'
    },
    image: ' https://sikkaapp.in/logo/logo-with-title.svg',
    logo: ' https://sikkaapp.in/logo/logo.svg'
  }
};
