import { Button } from '@chakra-ui/button';
import React from 'react';
import classes from './styles.module.scss';
import clsx from 'clsx';

// eslint-disable-next-line react/display-name
const SKButton = React.forwardRef((props, ref) => {
  const { className, isLink, gradient = true, bgColor, ...restProps } = props;
  return isLink ? (
    <a
      {...restProps}
      ref={ref}
      className={clsx(
        gradient && classes.gradient,
        classes.sikkaBtn,
        className
      )}
      style={{ backgroundColor: `${bgColor}` }}
    >
      {props.children}
    </a>
  ) : (
    <Button
      ref={ref}
      className={clsx(
        classes.sikkaBtn,
        gradient && classes.gradient,
        className
      )}
      {...restProps}
    >
      {props.children}
    </Button>
  );
});

export default SKButton;
