import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from 'react';
import { logout } from 'utils';
import AccountBlocked from 'components/AccountBlocked';

export const BannedStatusContext = createContext(null);
export function useBannedStatus() {
  return useContext(BannedStatusContext);
}

export default function BannedStatusProvider({ children }) {
  const [bannedModal, setBannedModal] = useState(false);

  const value = {
    bannedModal,
    setBannedModal,
  };
  function forceLogout() {
    logout();
    window.open('/', '_self');
  }

  useEffect(() => {
    if (bannedModal) {
      setTimeout(() => {
        forceLogout();
      }, 10000);
    }
  }, [bannedModal]);
  return (
    <BannedStatusContext.Provider value={value}>
      <>
        {children}
        {bannedModal && <AccountBlocked isOpen={true} onClose={forceLogout} />}
      </>
    </BannedStatusContext.Provider>
  );
}
