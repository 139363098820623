import { StorageService } from 'service/Storage';
import { PRODUCTION_MODE } from 'constants';

export const ENV =
  process.env.NEXT_PUBLIC_ENV === 'development' ? 'development' : 'production';

export const isProduction = ENV === 'production';

export const isDev = ENV === 'development';

export const isLocalhost = Boolean(
  typeof window !== 'undefined' &&
    (window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ))
);

export const API_END_POINT =
  isProduction && StorageService.get(PRODUCTION_MODE)
    ? process.env.NEXT_PUBLIC_API_END_PROD_POINT
    : process.env.NEXT_PUBLIC_API_END_POINT;

export const V1_1_API_END_POINT = API_END_POINT + '/v1.1';

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.sikka.freemoney&utm_source=sikkaweb&utm_medium=websikka&utm_campaign=sikkaweborganic';
