export const SHARE_VIA_WHATSAPP = 'share_via_whatsapp_contest_web';
export const SHARE_VIA_TELEGRAM = 'share_via_telegram_contest_web';
export const SHARE_VIA_CONTEST = 'share_via_contest_web';
export const CONTEST_CARD_CLICK = 'contest_card_click_web';
export const CONTEST_CARD_VISIBLE = 'contest_card_visible_web';
export const CONTEST_JOINED = 'contest_joined_web';
export const CONTEST_AUTH_FAILED = 'contest_auth_failed_web';
export const CONTEST_DETAILS_JOIN_NOW = 'contest_details_join_now_web';
export const CONTEST_DETAILS_GO_TO_OFFERS = 'contest_details_go_to_offers_web';
export const CONTEST_ERROR_BOUNDARY = 'contest_error_boundary_web';
export const GLOBAL_ERROR_BOUNDARY = 'global_error_boundary_web';
