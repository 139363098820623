// import '../styles/globals.css'
import '../styles/index.css';
import '../polyfill';
import '@fontsource/space-grotesk/400.css';
import '@fontsource/space-grotesk/700.css';
import 'config/config.styles.scss';
import { ChakraProvider } from '@chakra-ui/react';
import { ggSentry } from 'config/sentry';
import { ggAnalytics } from 'config/ga';
import ReloadApp from 'components/ReloadApp';
import { chakraTheme, ToastContainer } from 'utils';
// import reportWebVitals from '../reportWebVitals';
import AppErrorBoundary from 'components/ErrorBoundary';
import BannedStatusProvider from 'components/BannedModalWrapper';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
ggSentry.initialize(process.env.NEXT_PUBLIC_SENTRY);
ggAnalytics.initialize(process.env.NEXT_PUBLIC_GA);
function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    ggAnalytics.firePageView(router.asPath);
  }, []);

  return (
    <>
      <ReloadApp />
      <ChakraProvider theme={chakraTheme}>
        <AppErrorBoundary>
          <BannedStatusProvider>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <title>Daily Cash Rewards | Earn Real Money App - Sikka</title>
            </Head>
            <Component {...pageProps} />
            <ToastContainer />
          </BannedStatusProvider>
        </AppErrorBoundary>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
// reportWebVitals()
