import * as Sentry from '@sentry/browser';
import { isDev } from '../index';

const MockSentry = {
  init() {
    console.log('Sentry Init');
  },
  captureException(err) {
    console.log('Captured Err', err);
  },
  showReportDialog(data) {
    console.log('sentry: showReportDialog', data);
  },
  setExtra() {},
  withScope(cb) {
    console.log('Scope faked');
    cb({
      setExtras: error => {
        console.log(error);
      }
    });
  }
};

class GGSentry {
  constructor() {
    this.sentry = isDev ? MockSentry : Sentry;

    const originalConsoleError = console.error;

    console.error = (...args) => {
      //DON'T LOG METERIAL UI Warning to Sentry
      const errorMsg =
        args && args[0] && typeof args[0] === 'string' ? args[0] : '';
      if (errorMsg && errorMsg.startsWith('Warning:')) {
        originalConsoleError(...args);
        return;
      }

      this.catchExceptions(args);
      originalConsoleError(...args);
    };
  }

  initialize(dsn) {
    this.sentry.init({ dsn });
  }

  catchExceptions(err) {
    this.sentry.setExtra('error', err);
    this.sentry.captureException(err);
  }

  showReportDialog(data) {
    this.sentry.showReportDialog(data);
  }

  getInstance() {
    return this.sentry;
  }
}

export const ggSentry = new GGSentry();
